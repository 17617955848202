<template>
  <div class="product-list">
    <!-- 标题与保存按钮 -->
    <el-row>
      <el-col :span="12"><Title title="在线人数折线图"></Title></el-col>
      <el-col :span="12">
        <div style="width:100%;height:60px;padding-right:15px;box-sizing:border-box" class="flex_rec">
          <!-- <el-button type="primary" @click="addObj">添加音效</el-button> -->
        </div>
      </el-col>
    </el-row>
    <!-- 产品列表 -->
    <el-card class="box-card">
      <el-row :gutter="20" type="flex" align="middle" justify="start">
        <el-col :span="1.5">
          创建时间
        </el-col>
        <el-col :span="3">
          <el-date-picker
            @change="getList"
            v-model="search_op.create_time_range"
            type="daterange"
            value-format="timestamp"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </el-col>
      </el-row>
      <LineChart ref="lineChart" />
    </el-card>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="page_num"
      :page-sizes="[5,10,15,20,30,100,150,500,1000]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
import Title from "./components/Title"
import apis from "@/api/index"
import util from "@/lib/util.js"
import LineChart from './components/Line.vue'
export default {
    components:{
      Title,
      LineChart
    },
    name:'OnlineIndex',
    data(){
      function getTodayStartAndEnd() {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        
        const endOfDay = new Date();
        endOfDay.setHours(23, 59, 59, 999);

        return { start: today, end: endOfDay };
      }
      const { start, end } = getTodayStartAndEnd();
      let defaultTimeRange = [start.getTime(), end.getTime()]
      return {
        pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        order_type_arr:[
          {name:'最新',value:"newest"},
          {name:'频率',value:"frequency"},
        ],
        page_num:1,
        page_size:150,
        total:0,
        search_op:{
          order_type:"newest",
          create_time_range:defaultTimeRange
        },
      }
    },
    methods:{
			confirm(ob,callback){
				this.$confirm(ob.msg, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: !ob.type?'warning':ob.type
				}).then(() => {
					callback()
				}).catch((e) => {
					console.error(e)
					this.$message({
						type: 'info',
						message: '已取消'
					});          
				});
			},
      getList(toS=true){
        if(toS){
          this.page_num = 1;
        }
        let op = {
          page_num:this.page_num,
          page_size:this.page_size
        }
        Object.assign(op,this.search_op)
        apis.onlineUser.ReadOnlineCountLog(op).then(res=>{
          console.log('res',res)
          let chartData = {}
          this.list = (res.data.data.list || []).map((v)=>{
            return v;
          })
          let labels = (res.data.data.list || []).map((v)=>{
            return v.time;
          })
          let datasets_data = (res.data.data.list || []).map((v)=>{
            return v.count;
          })
          let datasets = [
            {
              label: '在线人数',
              backgroundColor: '#f87979',
              data: datasets_data
            }
          ]
          chartData.labels = labels
          chartData.datasets = datasets
          this.$refs.lineChart.setData(chartData)
          this.total = res.data.data.count
        }).catch(err=>{
          console.log('err',err)
        })
      },
      handleSizeChange(val) {
        this.page_size = val
        this.getList(false)
        console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        this.page_num = val
        this.getList(false)
        console.log(`当前页: ${val}`);
      },
    },
    activated(){
      this.getList(false)
    },
    mounted(){
      this.getList()

    }
}
</script>

<style lang="scss" scoped>
.product-list{
  padding:15px;
}

</style>